/* .glowonhover {
    width: 70px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glowonhover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glowonhover:active {
    color: #000
}

.glowonhover:active:after {
    background: transparent;
}

.glowonhover:before {
    opacity: 1;
}

.glowonhover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
} */

  
   /* p{
    color: #fff;
    text-align:center;
  }
   */
  /* a {
    display: inline-block;
    padding: 5px 20px;
    margin: 38px;
    font-size: 24px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 4px;
    transition: 0.5s;
  }  */
  
  /* .btns{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    flex-direction: row;  
  } */

  .pinkbtn{
    color: #e100ff;
    border: 5px solid #e100ff;
    border-radius: 7px;  
  }
  .pinkbtn:hover{
    background-color: #e100ff;
    color: #050801;
    box-shadow: 0px 0px 5px #e100ff, 0px 0px 25px #e100ff, 0px 0px 50px #e100ff, 0px 0px 100px #e100ff;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005); 
  }
 .navEdit{
    box-sizing: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
    background-color: rgba(91, 0, 90,0.5);
    transition: background-color 2.5s;
    
 }
/* /////////////////////////////// */

 .linkHover {
  background-image: linear-gradient(to right , #e100ff , #e100ff 50%, black 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 0 0;
  position: relative;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.5s ease-in-out;
  font-size: medium;
 
}

.linkHover::before {
content: "";
background: #e100ff;
display: block;
position: absolute;
bottom: -3px;
left: 0;
width: 0;
height: 2px;
transition: all 0.3s ease-in-out;

}

.linkHover:hover {
  background-position: 0;
}

.linkHover:hover::before {
  width: 100%;
} 
 
@media only screen and (max-width: 720px) {

  .offCanvasResponsiveMobile{
      width: 50% !important;
      margin: auto !important;  
  }
    }