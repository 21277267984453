
/* .pageTwoBG{
    background-color: #5B005A;
    box-shadow: 0px -10px 150px 150px #5B005A , 0px 10px 25px 10px #5B005A ;
} */

.bgFading{
  /* background-color: purple; */
  box-shadow: 0px -10px 150px 150px #5B005A , 0px 10px 25px 10px #5B005A ;
}

.boxShadow{
  transition: all 1s;
  border: 1px white solid;
    /* box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */
}
.boxShadow:hover{
 
  background-color: white;
  color: #5B005A !important;
  transition: all 1s;
}
.videoEditBG {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .videoContainer {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }

  .videoContent{
    position: relative;
    color: white;
    /* text-shadow: 1px 1px 8px rgba(0,0,0,0.6); */
  }
.zIndexOfAboutUs{
  z-index: 99;
}

.sideNav{
  position: fixed;
  left: 25px;
  top: 50%;
  font-size: 15px;
  z-index: 8;
}
.anchorSideNav{
  color: white;
  text-decoration: none;
  transition: all 1s;
 
}
.aboutUsParag{
  z-index: 999;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {

  .aboutUsParag{
    display:flex;
    flex-direction: column;
  }
 .sideNav{
  display: none !important;
 }

 .bgFading{
  display: none !important;
 }
  }