.middleTric{
    font-size: 40px;
    color: rgba(92, 111, 71, 0.1);
}
.shadowDiv{
    width: 500px;
    transition: all 1s;
    z-index: 999;
} 
.shadowDiv2{
    width: 100%;
    transition: all 1s;
    z-index: 999;
}  

.shadowDiv:hover{
    background-color: #5B005A;
    color: white;
    transition: all 1s;
}
.shadowDiv2:hover{ 
    background-color: #5B005A;
    color: white;
    transition: all 1s;
}

.movingHeader{
    background-color: #5B005A;
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
}

.paragUp{
    display: block;
    z-index: 9999 ;
}
@media only screen and (max-width: 768px) {

    .mobileResponsiveOurMissin{
        
        flex-direction: column; 
        
    }
    .shadowDiv{
        width: 250px;
        margin: auto;
        
    }
    }