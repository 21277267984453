.colorAnimatedBG{
    /* background-color: #5B005A;
    background: linear-gradient(-90deg, #5B005A, #7E0090, #5B005A, #7E0090);
	background-size: 500% 500%;
	animation: gradient 7.5s ease infinite; */
    background-image: url(../Media//services.webp);
    background-size: 100% 100%;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.bgImg{
    background-image: url(../Media/back.webp);
    background-size: cover;
}
.movingHeader{
    background-color: white;
    color: #5B005A;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
}
.whiteBG{
    background-color: white;
}
.purpleColor{
    color: #7E0090;
}

.paragHover{
	transition: all 0.5s;
}
.paragHover:hover{
	color: #5B005A !important;
	background-color: white;
	transition: all 0.5s;
}


/* .contactUsBtnEdit{
    background-color:#EFBC83 !important;
    font-weight: 900 !important;
    border-radius: 20px !important;
    color: black !important; 
} */
.contactUsIconsPosition{
    border: gray solid 1px;
   background-color: rgba(255, 255, 255,0.9);
}
.contactUsBtnEdit:hover{
    background-color: #EC722B !important;
}
.iconsBorder{
    border: purple 1px solid;
    background-color: white;
    /* border-radius: 10px; */
}
.LinkEdit{
    text-decoration: none !important;
    color: #5B005A !important;
}
.iconColor{
    color: #5B005A;
}

/* @media only screen and (max-width: 1200px) {

    .mobileResponsive{
  
      flex-direction: column !important;
      justify-content: center !important;
    }
      } */