.colorAnimatedBG{
    
    /* background-color: #5B005A;
    background: linear-gradient(-90deg, #5B005A, #7E0090, #5B005A, #7E0090);
	background-size: 500% 500%;
	animation: gradient 7.5s ease infinite; */
	background-image: url(../Media/technal\ new.webp);
	background-size: 100% 100%;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.movingHeader{
    background-color: transparent;
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
}
.whiteBG{
    background-color: white;
}
.purpleColor{
    color: #7E0090;
}

.paragHover{
	transition: all 0.5s;
	display: none;
}
.paragHover:hover{
	color: #5B005A !important;
	background-color: white;
	transition: all 0.5s;
}

@media only screen and (max-width: 768px) {

    .Mobileresponsive{
  
      flex-direction: column !important;
      justify-content: center !important;
    }
      }