* {
  box-sizing: border-box;
}

.shortline{
	margin: auto;
	margin-bottom: 3px;
	width: 80px;
	height: 1px;
	background-color: white;
  }
  .longline{
	margin: auto;
	margin-bottom: 3px;
	width: 150px;
	height: 2px;
	background-color: white;
  }

  .shortline2{
    margin: auto;
    margin-bottom: 3px;
    width: 80px;
    height: 1px;
    background-color:#7E0090 ;
    }
    .longline2{
    margin: auto;
    margin-bottom: 3px;
    width: 150px;
    height: 2px;
    background-color:#7E0090 ;
    }