/* .shadowDiv{
    box-shadow: rgba(92, 0, 90 ,0.3) 0px 50px 100px -20px, rgba(92, 0, 90 ,0.3) 0px 30px 60px -30px, rgba(92, 0, 90 ,0.3) 0px -2px 6px 0px inset;
    
} */
.PageFiveBg{
    background-color: white;
 
}
.pageFiveEditSpace{
    background-color: white;
}
.colorPurple{
    color: #660066;
}
.BtnEdit{
    background-color:#660066 !important;
    color: white !important;
    text-decoration: none !important;
}