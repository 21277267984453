.BGshadow{
    /* box-shadow: rgba(92, 0, 90 ,0.3) 0px 50px 100px -20px, rgba(92, 0, 90 ,0.3) 0px 30px 60px -30px, rgba(92, 0, 90 ,0.3) 0px -2px 6px 0px inset; */
    margin-top: 200px !important;
    margin-bottom: 50px !important;
}
.middleTric{
    font-size: 45px;
    color: rgba(92, 111, 71, 0.1);
}

    
  .carousel {
      height: 700px;
      perspective: 600px;
      transform: translateY(-100px);
  }
  
  .carousel .carouselitem {
      cursor: grab;
      width: 400px;
  }
  
  .carousel .carouselitem:active {
      cursor: grabbing;
  }
  
  .carousel .carouselitem img {
      width: 100%;
  }
  
  .carousel .carouselitem h3 {
      background-color: #ffffff;
      color: #000000;
      font-size: 2em;
      font-weight: bold;
      margin: -5px 0 0;
      padding: 10px 5px;
      text-align: center;
  }

  .sliderImgEditSize{
    width: 400px;
    height: 400px;
  }

  

  @media only screen and (max-width: 768px) {

    .sliderImgEditSize{
        width: 200px;
        height: 200px;
    }
    .BGshadow{
        margin-top: 0px !important;
        margin-bottom: 50px !important;
    }
    }