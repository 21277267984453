
    .layer{

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #5B005A;
		background: linear-gradient(-90deg, rgb(91, 0, 90), rgb(126, 0, 144), rgb(91, 0, 90), rgb(126, 0, 144));
		background-size: 500% 500%;
		animation: gradient 7.5s ease infinite;
		
	}

	.textFooterEdit{
		color: white;
		font-size: 100px;
	}

	.arrowEdit{
		border: 4px white solid;
		padding: 40px;
		font-size: 50px;
		transition: all 0.25s;
		background-color: white !important;
		color: #5B005A !important;
	  }
	  .arrowEdit:hover{
		border: 4px white solid;
		background-color: transparent !important;
		color: white !important;
		transition: all 0.25s;
		transform: scale(1.1);
	  }

   .imgFooterHover:hover{
	transform: translate3d(0.53px, -1.49px, 0px) !important;
   }

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media only screen and (max-width: 768px) {

.arrowEdit{
	font-size: 30px ;
	padding: 25px;
	border: 2px white solid;
}

.arrowEdit:hover{
	border: 2px white solid;
	background-color: transparent !important;
	color: white !important;
	transition: all 0.25s;
  }

  .textFooterEdit{
	color: white;
	font-size: 50px;
}
	} 