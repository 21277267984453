
.videoEditBG {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .videoContainer {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #5B005A;
    box-shadow: 0px -10px 150px 150px #5B005A , 0px 10px 25px 10px #5B005A ;
    z-index: 9;
  }

  .videoContent{
    height: 100%;
    width: 100%;
    position: relative;
    color: white;
  }

  .arrowEdit{
    border: 4px white solid;
    padding: 40px;
    font-size: 50px;
    transition: all 0.25s;
    background-color: white !important;
    color: #5B005A !important;
  }
  .arrowEdit:hover{
    border: 4px white solid;
    background-color: transparent !important;
    color: white !important;
    transition: all 0.25s;
    transform: scale(1.1);
  }
.editSection{
    /* border: 2px white solid; */
    border-radius: 30px;
    background-color: white;
    color:#5B005A;
    margin-left: 10px;
    transition: all 1s;
}
/* .editSection:hover{
  border-radius: 30px;
  background-color: white;
  color:#5B005A;
  margin-left: 10px;
  transition: all 1s;
} */
.btnHomeEdit{
  background-color: #5B005A !important;
  border-radius: 50px !important;
  color: white !important;
  width: 100px !important;
  margin-right: 5px;
  transform: scale(0.9);
  transition: all 0.25s;
}
.btnHomeEdit:hover{
 transform: scale(1);
 transition: all 0.25s;
}


@media only screen and (max-width: 1117px) {

  .editSection{

    display: none !important;
  }
    }